import React, { useState, useEffect, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const API_BASE_URL = 'https://apiyes.qwe123.win/api';

const Card = ({ children, title }) => (
  <div style={{
    backgroundColor: '#2a2a2a',
    border: '1px solid #444',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px',
    color: '#e0e0e0'
  }}>
    {title && <h3 style={{ marginTop: 0, color: '#fff' }}>{title}</h3>}
    {children}
  </div>
);

const NumberFormatter = ({ number }) => {
  const formatNumberToEnglishUnits = (number) => {
    if (number < 1000) {
      return number.toString();
    }

    const options = {
      notation: "compact",
      compactDisplay: "short",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    return new Intl.NumberFormat("en-US", options).format(number);
  };

  return (
    <div>
      {formatNumberToEnglishUnits(number)}
    </div>
  );
}

const Modal = ({ isOpen, onClose, children, title, onCopy }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000
    }}>
      <div style={{
        backgroundColor: '#0C0C0C',
        border: '1px solid #444',
        borderRadius: '4px',
        padding: '20px',
        width: '80%',
        maxWidth: '600px',
        maxHeight: '80%',
        overflowY: 'auto'
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <h3 style={{ color: '#fff', margin: 0 }}>{title}</h3>
          <div>
            <button onClick={onCopy} style={{
              backgroundColor: '#333',
              color: '#fff',
              border: 'none',
              padding: '5px 10px',
              cursor: 'pointer',
              marginRight: '10px'
            }}>Copy to Clipboard</button>
            <button onClick={onClose} style={{
              backgroundColor: '#333',
              color: '#fff',
              border: 'none',
              padding: '5px 10px',
              cursor: 'pointer'
            }}>Close</button>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

const ActionButton = ({ onClick, children }) => (
  <button
    onClick={onClick}
    style={{
      backgroundColor: '#444',
      color: '#fff',
      border: 'none',
      padding: '5px 10px',
      margin: '0 5px',
      borderRadius: '3px',
      cursor: 'pointer',
      fontSize: '12px'
    }}
  >
    {children}
  </button>
);
// const NumberFormatter = ({ number }) => {
//   const formatNumberToEnglishUnits = (number) => {
//     if (number < 1000) {
//       return number.toString();
//     }

//     const options = {
//       notation: "compact",
//       compactDisplay: "short",
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2
//     };
//     return new Intl.NumberFormat("en-US", options).format(number);
//   };

//   return (
//     <div>
//       {formatNumberToEnglishUnits(number)}
//     </div>
//   );
// }
const MiningRateCell = React.memo(({ rate }) => {
  const formatNumberToEnglishUnits = (number) => {
    if (number < 1000) {
      return (<div 
        className="mining-rate-cell"
        style={{ 
          padding: '12px 8px',
          borderBottom: '1px solid #444',
        }}
      >
        {number.toString()} Y/m
      </div>
      );
    }

    const options = {
      notation: "compact",
      compactDisplay: "short",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    return (<div 
      className="mining-rate-cell"
      style={{ 
        padding: '12px 8px',
        borderBottom: '1px solid #444',
      }}
    >
      {new Intl.NumberFormat("en-US", options).format(number)} Y/m
    </div>
    );
  };

  return formatNumberToEnglishUnits(rate);
});

const MinerRow = ({ index, style, data }) => {
  const miner = data.miners[index];
  return (
    <div style={{
      ...style,
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #444',
      backgroundColor: index % 2 === 0 ? '#2a2a2a' : '#1a1a1a',
    }}>
      <div style={{ flex: 1, padding: '8px' }}>{miner.computerIndex}</div>
      <div style={{ flex: 1, padding: '8px' }}>{miner.deviceName}</div>
      {/* <div style={{ flex: 1, padding: '8px' }}>
        <MiningRateCell rate={miner.hashRate} />
      </div> */}
      <div style={{ flex: 1, padding: '8px' }}>
        <NumberFormatter number={miner.coinsMined.toFixed(2)}/>
      </div>
      <div style={{ flex: 1, padding: '8px' }}>
        {miner.swipebot}
      </div>
      <div style={{ flex: 1, padding: '8px', color: miner.status === 'Online' ? '#4CAF50' : '#F44336' }}>
        {miner.status}
      </div>
      <div style={{ flex: 1, padding: '8px' }}>
        <ActionButton onClick={() => data.viewConsoleLogs(miner.computerIndex, miner.deviceName)}>
          로그보기
        </ActionButton>
      </div>
    </div>
  );
};

const MiningDashboard = () => {
  const [miners, setMiners] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', logs: [] });
  const [totalCoinsMined, setTotalCoinsMined] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [listHeight, setListHeight] = useState(0);

  const fetchMiners = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/miners`);
      const data = await response.json();
      setMiners(data);
      const total = data.reduce((sum, miner) => sum + miner.coinsMined, 0);
      setTotalCoinsMined(total);
    } catch (error) {
      console.error('Error fetching miners:', error);
    }
  };

  useEffect(() => {
    fetchMiners();
    const interval = setInterval(() => {
      fetchMiners();
    }, 3000); // Fetch every 3 seconds

    const updateListHeight = () => {
      const windowHeight = window.innerHeight;
      const offsetTop = document.getElementById('minerList').offsetTop;
      setListHeight(windowHeight - offsetTop - 32); // 32px for bottom margin
    };

    updateListHeight();
    window.addEventListener('resize', updateListHeight);

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', updateListHeight);
    };
  }, []);

  const totalHashRate = miners.reduce((sum, miner) => sum + miner.hashRate, 0);
  const onlineMachines = miners.filter(miner => miner.status === 'Online').length;

  const viewConsoleLogs = async (computerIndex, deviceName) => {
    try {
      const response = await fetch(`${API_BASE_URL}/miner/logs?computerIndex=${computerIndex}&deviceName=${deviceName}`);
      const logs = await response.json();
      setModalContent({
        title: `Console Log - ${computerIndex} - ${deviceName}`,
        logs: logs
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching logs:', error);
      alert('Failed to fetch logs. Check console for details.');
    }
  };

  const copyLogsToClipboard = () => {
    const logText = modalContent.logs.map(log => `${log.timestamp}: ${log.message}`).join('\n');
    navigator.clipboard.writeText(logText).then(() => {
      alert('Logs copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const sortedMiners = useMemo(() => {
    let sortableMiners = [...miners];
    if (sortConfig.key !== null) {
      sortableMiners.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableMiners;
  }, [miners, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
    }
    return '';
  };

  return (
    <div style={{ padding: '16px', backgroundColor: '#1a1a1a', color: '#e0e0e0', minHeight: '100vh' }}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>마이닝 대시보드 (PC 환경에 최적화 되어있습니다)</h1>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', marginBottom: '16px' }}>
        <Card title="채굴된 코인">
          <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#4CAF50' }}>
            {totalCoinsMined.toLocaleString()}개
          </div>
        </Card>
        <Card title="마이닝 레이트 (Y/m)">
          <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#4CAF50' }}>
            <NumberFormatter number={totalHashRate.toFixed(2)}/>
          </div>
        </Card>
        <Card title="온라인 디바이스">
          <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#2196F3' }}>{onlineMachines}/{miners.length}</div>
        </Card>
      </div>
      <style jsx>{`
        @keyframes highlight {
          0% { color: #e0e0e0; font-weight: normal; }
          50% { color: #FFD700; font-weight: bold; }
          100% { color: #e0e0e0; font-weight: normal; }
        }
        .mining-rate-cell {
          animation: highlight 1s ease;
        }
      `}</style>

      <Card title="Miner Details">
        <div id="minerList" style={{ height: `${listHeight}px`, width: '100%' }}>
          <AutoSizer>
            {({ height, width }) => (
              <>
                <div style={{ display: 'flex', width: width, borderBottom: '1px solid #444', backgroundColor: '#2a2a2a' }}>
                  <div style={{ flex: 1, padding: '12px 8px', color: '#fff', cursor: 'pointer' }} onClick={() => requestSort('computerIndex')}>
                    컴퓨터 번호{getSortIndicator('computerIndex')}
                  </div>
                  <div style={{ flex: 1, padding: '12px 8px', color: '#fff', cursor: 'pointer' }} onClick={() => requestSort('deviceName')}>
                    디바이스{getSortIndicator('deviceName')}
                  </div>
                  {/* <div style={{ flex: 1, padding: '12px 8px', color: '#fff', cursor: 'pointer' }} onClick={() => requestSort('hashRate')}>
                    마이닝 레이트 (Y/m){getSortIndicator('hashRate')}
                  </div> */}
                  <div style={{ flex: 1, padding: '12px 8px', color: '#fff', cursor: 'pointer' }} onClick={() => requestSort('coinsMined')}>
                    코인{getSortIndicator('coinsMined')}
                  </div>
                  <div style={{ flex: 1, padding: '12px 8px', color: '#fff', cursor: 'pointer' }} onClick={() => requestSort('swipebot')}>
                    오토봇{getSortIndicator('swipebot')}
                  </div>
                  <div style={{ flex: 1, padding: '12px 8px', color: '#fff', cursor: 'pointer' }} onClick={() => requestSort('status')}>
                    상태{getSortIndicator('status')}
                  </div>
                  <div style={{ flex: 1, padding: '12px 8px', color: '#fff' }}>액션</div>
                </div>
                <List
                  height={height - 40} // Subtract header height
                  itemCount={sortedMiners.length}
                  itemSize={50}
                  width={width}
                  itemData={{ miners: sortedMiners, viewConsoleLogs }}
                >
                  {MinerRow}
                </List>
              </>
            )}
          </AutoSizer>
        </div>
      </Card>

      <Modal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
        title={modalContent.title}
        onCopy={copyLogsToClipboard}
      >
        <div style={{
          backgroundColor: '#0C0C0C',
          color: '#00FF00',
          fontFamily: 'monospace',
          padding: '10px',
          borderRadius: '4px',
          whiteSpace: 'pre-wrap',
          textAlign: 'left'
        }}>
          {modalContent.logs.map((log, index) => (
            <div 
              key={index} 
              style={{ 
                color: log.log_type === 'error' ? '#FF0000' : '#00FF00',
                marginBottom: '5px'
              }}
            >
              {log.timestamp}: {log.message}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default MiningDashboard;